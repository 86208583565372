import { useEffect, useState } from "react";
import Modal from "react-modal";
import ISO6391 from "iso-639-1";
import styled from "styled-components";
import { Input } from "../../../../../components/Inputs/Input";
import { BaseText } from "../../../../../components/Text";
import { ConfirmationButton } from "../../../../../components/Buttons/ConfirmationButton";
import { SelectInput } from "../../../../../components/Inputs/SelectInput";
import {makeServiceUrl} from "../../../../../utils/completion-utils";

const languageOptions = [
  "en", "de"
].map(value => ({
  value,
  label: ISO6391.getName(value),
}));

const Title = styled(BaseText)`
  text-align: center;
  font-weight: 500;
  font-size: 130%;
  color: ${({ theme }) => theme.colors.nearlyBlack};
  margin: 20px;
`;

interface SparkCreateModalProps {
  open: boolean;
  onClose: () => void;
  onSparkGame: (game: any) => void;
}

export const SparkCreateModal = ({ open, onClose, onSparkGame }: SparkCreateModalProps) => {
  const [ language, setLanguage ] = useState<any>(languageOptions[0]);
  const [ topic, setTopic ] = useState('');
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if (!open) {
      return;
    }

    setLoading(false);
  }, [open])

  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    fetch(makeServiceUrl("spark", "v1/games"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        topic,

        language: language.value,

        difficultyMin: 33,
        difficultyMax: 43,
      }),
    }).then(res => res.json()).then(json => {
      onSparkGame(json);
    });
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      contentLabel=""
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <Title>Create with Spark</Title>

      <form onSubmit={onSubmit}>
        <Input
          label="Topic"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
        />

        <SelectInput
          label="Language"
          value={language}
          onChange={(e) => setLanguage(e)}
          options={languageOptions}
        />

        <ConfirmationButton
          disabled={loading}
          style={{
            height: 'auto',
            opacity: loading ? 0.2 : 1,
          }}
        >
          { loading ? "Loading..." : "Submit" }
        </ConfirmationButton>
      </form>
    </Modal>
  );
};
